<template>
  <div class="flex flex-col mb-10">

    <div style="margin-left: -10px; padding:30px; border: 1px solid #8080807a; border-radius: 25px; border-radius: 5px;">
    
        <div class="flex my-3 ml-6">
        <Content text-victoria class="my-3 text-uppercase w-full"  text="Number of One To One Appointment" /> 
        <md-checkbox class="w-32" @change="selectAllAccess($event)" v-model="form.one_to_one_appointment_all_access">All Access</md-checkbox>
        </div>
        <div class="flex my-3 ml-6">
            <InputFieldComponent inputClass='py-3'  type="range" class="w-full" min="0"  max="100" step="1" v-model="rangeSlider" />
            <InputFieldComponent  type="number" v-model="form.OneToOneAppointment" @input="updateSlider"  class="w-20 ml-5" />
        </div>        
    </div>   

    <div class="flex justify-end mt-4">
      <!-- <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button> -->
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  InputFieldComponent
} from "@/components";
import Content from "@/components/atom/Content";


export default {
  components: {
    Button,
    InputFieldComponent,
    Content 
  },

  data() {
    return {
      rangeSlider: 0,
      form: {
        OneToOneAppointment:0,
        one_to_one_appointment_all_access: false
      },
      loading: false,
      
      
    }
  },
  computed:{
  
   },
   watch: {
    rangeSlider(range) {
      if(range=='ALL'){
        this.form.OneToOneAppointment = "ALL"; 
      }else{
        this.form.OneToOneAppointment = range*10;  
      }
    }
  },
   methods:{

    selectAllAccess(id){
      if(id){
         this.form.OneToOneAppointment = 'ALL';
         this.rangeSlider=100;
      }else{
         this.form.OneToOneAppointment = 0;
         this.rangeSlider=0
      }
    },  

    formClear(){
      this.form = {
        OneToOneAppointment: 0
      }
    },
    updateSlider(value){
      this.rangeSlider=value/10;
    },
    submit() {
      
      let formData = this.getLocal('course-create-info');
      formData.package_detail = {
         ...formData.package_detail,
         ...this.form
         
      };
      this.setLocal('course-create-info', formData);

      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'courseCreation.durationAndPrice' });
    },

    clearAll() {
      this.$emit("clear-all", "");
    }
   },


  created() {

    let existData = this.getLocal('course-create-info');
    if (existData && existData.package_detail) {
      this.form = existData.package_detail; 
      if(this.form.OneToOneAppointment) this.rangeSlider=this.form.OneToOneAppointment/10;
    }  
  }
}
</script>